.container {
    border: solid 1px #54afff;
    max-width: 500px;
    padding: 1em 2em 1em 1em;
    margin-bottom: 1em;
}

.formItem {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 2em;
}

.formItem > div, .formItem > button {
    float: right;
}