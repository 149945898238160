.dropdown {
    width: 200px;
}

.container {
    margin-bottom: 2em;
}

.label {
    font-size: large;
    margin-right: 1em;
}